import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image/withIEPolyfill';
import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { Logo } from '../components/Logo';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query NotFoundPageQuery {
      tessa: file(absolutePath: { regex: "/tessa.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Not Found" />
      <div className="flex flex-col min-h-screen p-5 sm:justify-center sm:items-center sm:text-center">
        <Link
          to="/"
          aria-label="Kitwind"
          title="Kitwind"
          className="inline-block mb-6"
        >
          <Logo className="w-40" />
        </Link>
        <h1 className="mb-2 text-4xl font-extrabold leading-none sm:text-5xl md:text-6xl">404</h1>
        <h3 className="mb-2 text-lg font-medium text-gray-900 sm:text-2xl">
          It looks like you've got lost, buddy.
        </h3>
        <p className="max-w-sm mb-6 text-gray-700">
          Of course, you can{' '}
          <Link
            to="/"
            aria-label="Kitwind"
            title="Kitwind"
            className="font-semibold text-indigo-600 transition-colors duration-200 hover:text-indigo-800"
          >
            go home
          </Link>
          , but since you're here, let's first enjoy our cute cat Tessa:
        </p>
        <Image
          fluid={data.tessa.childImageSharp.fluid}
          title="Tessa"
          alt="Tessa"
          className="w-full max-w-md rounded shadow-xl"
        />
      </div>
    </Layout>
  );
};

export default NotFoundPage;
